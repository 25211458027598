import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import { Routing } from './Routing'
import { theme } from './themes/global'
import { Analytics } from 'analytics'

const App = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      Analytics.init()
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <BrowserRouter>
        <Routing />
      </BrowserRouter>
    </ThemeProvider>
  )
}

const root = createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
