import { Box, Stack, Typography, MobileStepper, Button } from '@mui/material'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import React, { useState, useRef } from 'react'

import { useScrollPage } from 'hooks'

import Arm from 'assets/images/arm.png'
import Arm_1 from 'assets/images/arm_1.png'
import Arm_2 from 'assets/images/arm_2.png'

const steps = [
  {
    title: 'Discover Recommendations from cafes and restaurants near you',
    image: Arm_2,
  },
  {
    title: 'Found some new delicious dish? Share this Recommendation with your friends!',
    image: Arm_1,
  },
  {
    title: 'Save Recommendations from your friends, to try them later',
    image: Arm,
  },
]

export const How = () => {
  const [activeStep, setActiveStep] = useState(0)

  const sectionRef = useRef(null)
  useScrollPage('HOW_TO_USE', sectionRef)

  return (
    <Box ref={sectionRef} p={{ xs: 2, md: 6 }} backgroundColor="rgba(242,61,61, 0.91)">
      <Stack
        direction={{ xs: 'column-reverse', md: 'row' }}
        justifyContent="space-between"
        alignItems="center"
        gap={4}
        maxWidth={1240}
        margin="0 auto"
      >
        <Stack maxWidth={400} gap={2} textAlign={{ xs: 'center', md: 'left' }} mt={4}>
          <Typography variant="h3" fontSize={32} maxWidth={800}>
            How to use Mealjoy app
          </Typography>
          <Typography variant="h2" fontSize={40} color="#FFA0A0">
            Step {activeStep + 1}
          </Typography>
          <Typography fontSize={24}>{steps[activeStep].title}</Typography>
          <MobileStepper
            variant="dots"
            steps={3}
            position="static"
            activeStep={activeStep}
            sx={{
              maxWidth: 200,
              width: '100%',
              margin: '16px auto 0',
              background: 'none',
              '.MuiMobileStepper-dot': { backgroundColor: '#F97575' },
              '.MuiMobileStepper-dotActive': { backgroundColor: '#fff' },
            }}
            nextButton={
              <Button
                size="small"
                onClick={() => activeStep !== 2 && setActiveStep((prev) => prev + 1)}
                variant="outlined"
                color="secondary"
                sx={{ minWidth: '28px', padding: 1, opacity: activeStep === 2 ? 0.5 : 1 }}
              >
                <ArrowForward sx={{ color: '#fff' }} />
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={() => activeStep !== 0 && setActiveStep((prev) => prev - 1)}
                variant="outlined"
                color="secondary"
                sx={{ minWidth: '28px', padding: 1, opacity: activeStep === 0 ? 0.5 : 1 }}
              >
                <ArrowBack sx={{ color: '#fff' }} />
              </Button>
            }
          />
        </Stack>
        <Box>
          <img
            src={steps[activeStep].image}
            style={{ maxWidth: 680, width: '100%', transform: 'translateY(55px)' }}
          />
        </Box>
      </Stack>
    </Box>
  )
}
