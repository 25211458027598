import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import { Home, PrivacyPolicy, TermsAndConditions } from 'pages'
import { PageLayout } from './PageLayout'

export const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<PageLayout />}>
        <Route index element={<Home />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="terms-conditions" element={<TermsAndConditions />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  )
}
