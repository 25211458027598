import React from 'react'
import { Box, Typography } from '@mui/material'

export const Card = ({ text, title, image, maxWidth }) => {
  return (
    <Box
      key={text}
      p={5}
      pb={0}
      sx={{
        boxShadow: '0 5px 35px #293a541a',
        borderRadius: 10,
        maxWidth: 640,
        width: '100%',
      }}
      textAlign="center"
    >
      <Typography variant="h3" mb={1} textAlign="center">
        {title}
      </Typography>
      <Typography fontSize={18} color="#5a5d67" textAlign="center">
        {text}
      </Typography>
      <img src={image} style={{ maxWidth, width: '100%', marginTop: 40 }} />
    </Box>
  )
}
