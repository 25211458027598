import React from 'react'
import { Box, Stack, Link } from '@mui/material'

import { Analytics } from 'analytics'

import logo from 'assets/logo.svg'
import App_Store_Button from 'assets/images/app_store_button.svg'

export const Header = () => {
  const handleDownloadClick = () => {
    Analytics.clickDownloadButton()
  }

  return (
    <Box
      component="header"
      position="sticky"
      zIndex={(theme) => theme.zIndex.appBar}
      top={0}
      sx={{ backgroundColor: '#ffffff', p: '16px 0', boxShadow: '0 2px 20px #5780be1a' }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        maxWidth={1240}
        margin="0 auto"
      >
        <Link href="/" display="inline-flex" flex={1} ml={2} maxWidth={{ xs: 125, md: 180 }}>
          <img src={logo} alt="" style={{ width: '100%' }} />
        </Link>
        <Link
          sx={{ mr: 2 }}
          component="button"
          onClick={() => {
            handleDownloadClick()
            window.open(
              'https://apps.apple.com/us/app/mealjoy-food-recommendation/id6504121432',
              '_blank',
              'noopener,noreferrer'
            )
          }}
        >
          <img src={App_Store_Button} alt="" />
        </Link>
      </Stack>
    </Box>
  )
}
