import { Box, Link, Stack, Typography } from '@mui/material'
import React, { useRef } from 'react'

import { useScrollPage } from 'hooks'

import Logo from 'assets/logo-white.svg'
import Linkedin from 'assets/icons/linkedin.png'
import Instagram from 'assets/icons/instagram.png'
import { Analytics } from 'analytics'

export const Footer = () => {
  const sectionRef = useRef(null)
  useScrollPage('FOOTER', sectionRef)

  return (
    <Box ref={sectionRef} p={{ xs: 2, md: 6 }} backgroundColor="rgba(242,61,61, 0.91)">
      <Box
        maxWidth={1240}
        margin="0 auto"
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        gap={6}
      >
        <Stack alignItems={{ xs: 'center', md: 'start' }}>
          <img src={Logo} style={{ maxWidth: 200, width: '100%' }} />
          <Link underline="always" color="#fff" href="terms-conditions">
            Terms & Conditions
          </Link>
          <Link underline="always" color="#fff" href="privacy-policy">
            Privacy Policy
          </Link>
          <Link underline="always" color="#fff" href="mailto:jarvis@mealjoyapp.com">
            Contact us
          </Link>
        </Stack>
        <Stack alignItems={{ xs: 'center', md: 'end' }} justifyContent="space-between" gap={2}>
          <Stack direction="row" gap={2}>
            <Link
              component="button"
              onClick={() => {
                Analytics.clickToLinkedinLink()
                window.open('https://www.linkedin.com/company/mealjoyapp/', '_blank')
              }}
            >
              <img src={Linkedin} style={{ maxWidth: 40, width: '100%' }} />
            </Link>
            <Link
              component="button"
              onClick={() => {
                Analytics.clickToInstagramLink()
                window.open(
                  'https://www.instagram.com/mealjoy_life?igsh=YTVyZHcya213MWRl',
                  '_blank'
                )
              }}
            >
              <img src={Instagram} style={{ maxWidth: 40, width: '100%' }} />
            </Link>
          </Stack>
          <Typography color="#fff">© 2024 All rights reserved.</Typography>
        </Stack>
      </Box>
    </Box>
  )
}
