import React from 'react'
import { Box, Link, Stack, Typography } from '@mui/material'

export const PrivacyPolicy = () => {
  return (
    <Box p={{ xs: 2, md: 6 }} maxWidth={1240} sx={{ margin: '0 auto' }}>
      <Typography variant="h3" textAlign="center">
        Privacy Policy
      </Typography>

      <Stack mt={4} gap={4}>
        <Stack gap={2}>
          <Typography fontSize={16} variant="body2">
            This Privacy Notice for Mealjoy, describes how and why we might access, collect, store,
            use, and/or process your personal information when you use our services
            <br />
            <br />
            Reading this Privacy Notice will help you understand your privacy rights and choices. We
            are responsible for making decisions about how your personal information is processed.
            If you do not agree with our policies and practices, please do not use our Services.
          </Typography>
        </Stack>
        <Stack gap={2}>
          <Typography fontWeight={600} fontSize={20}>
            Personal information you disclose to us
          </Typography>
          <Typography fontSize={16} variant="body2">
            We collect personal information that you voluntarily provide to us when you register on
            the Services, express an interest in obtaining information about us or our products and
            Services, when you participate in activities on the Services, or otherwise when you
            contact us.
            <br />
            <br />
            All personal information that you provide to us must be true, complete, and accurate,
            and you must notify us of any changes to such personal information.
          </Typography>
        </Stack>

        <Stack gap={2}>
          <Typography fontWeight={600} fontSize={20}>
            Information automatically collected
          </Typography>
          <Typography fontSize={16} variant="body2">
            We automatically collect certain information when you visit, use, or navigate the
            Services. This information does not reveal your specific identity (like your name or
            contact information) but may include device and usage information, such as your IP
            address, browser and device characteristics, operating system, language preferences,
            referring URLs, device name, country, location, information about how and when you use
            our Services, and other technical information. This information is primarily needed to
            maintain the security and operation of our Services, and for our internal analytics and
            reporting purposes.
          </Typography>
        </Stack>

        <Stack gap={2}>
          <Typography fontWeight={600} fontSize={20}>
            Tracking Technologies
          </Typography>
          <Typography variant="body2">
            We may use cookies and similar tracking technologies (like web beacons and pixels) to
            gather information when you interact with our Services. Some online tracking
            technologies help us maintain the security of our Services, prevent crashes, fix bugs,
            save your preferences, and assist with basic site functions.
            <br />
            <br />
            We also permit third parties and service providers to use online tracking technologies
            on our Services for analytics and advertising, including to help manage and display
            advertisements, to tailor advertisements to your interests, or to send abandoned
            shopping cart reminders (depending on your communication preferences). The third parties
            and service providers use their technology to provide advertising about products and
            services tailored to your interests which may appear either on our Services or on other
            websites.
          </Typography>
        </Stack>

        <Stack gap={2}>
          <Typography fontWeight={600} fontSize={20}>
            Your Privcy Rights
          </Typography>
          <Typography variant="body2">
            If we are relying on your consent to process your personal information, which may be
            express and/or implied consent depending on the applicable law, you have the right to
            withdraw your consent at any time. You can withdraw your consent at any time by
            contacting us by using the contact details provided in the section
            <br />
            <br />
            However, please note that this will not affect the lawfulness of the processing before
            its withdrawal nor, when applicable law allows, will it affect the processing of your
            personal information conducted in reliance on lawful processing grounds other than
            consent.
          </Typography>
        </Stack>

        <Stack gap={2}>
          <Typography fontWeight={600} fontSize={20}>
            Updates to this Notice
          </Typography>
          <Typography variant="body2">
            We are the owner or the licensee of all intellectual property rights in our Services,
            including all source code, databases, functionality, software, website designs, audio,
            video, text, photographs, and graphics in the Services (collectively, the
            &quot;Content&quot;), as well as the trademarks, service marks, and logos contained
            therein (the &quot;Marks&quot;)
            <br />
            <br />
            Our Content and Marks are protected by copyright and trademark laws (and various other
            intellectual property rights and unfair competition laws) and treaties in the United
            States and around the world.
            <br />
            <br />
            The Content and Marks are provided in or through the Services &quot;AS IS&quot; fro your
            personal, non-commercial use or internal business purpose
          </Typography>
        </Stack>

        <Stack gap={2}>
          <Typography fontWeight={600} fontSize={20}>
            Contact
          </Typography>
          <Typography variant="body2">
            Questions, comments and requests regarding this privacy policy are welcomed and should
            be addressed to <Link href={`mailto:jarvis@mealjoyapp.com`}>jarvis@mealjoyapp.com</Link>
          </Typography>
        </Stack>
      </Stack>
    </Box>
  )
}
