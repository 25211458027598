import React from 'react'

import { Main, Problems, How, Solution, CallToAction } from 'components'

export const Home = () => {
  return (
    <>
      <Main />
      <Problems />
      <Solution />
      <How />
      <CallToAction />
    </>
  )
}
