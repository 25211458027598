import React from 'react'
import { Box, Link, Stack, Typography } from '@mui/material'

export const TermsAndConditions = () => {
  return (
    <Box p={{ xs: 2, md: 6 }} maxWidth={1240} sx={{ margin: '0 auto' }}>
      <Typography variant="h3" textAlign="center">
        Terms and Conditions
      </Typography>

      <Stack mt={4} gap={4}>
        <Stack gap={2}>
          <Typography fontWeight={600} fontSize={20}>
            Your Acceptance
          </Typography>
          <Typography fontSize={16} variant="body2">
            These Terms of Service (“Terms”) govern your access and use of Mealjoy (the “Service”,
            “We”, “Website”), which can be accessed via URL (https://mealjoyapp.com). These Terms
            apply to all visitors, users and others who access or use the Service. Please read them
            carefully.
            <br />
            <br />
            by accessing or using the service you agree to be bound by these terms. if you disagree
            with any part of the terms then you may not access the service. the terms may be changed
            at any time and you must read these terms for any changes before using this website.
          </Typography>
        </Stack>

        <Stack gap={2}>
          <Typography fontWeight={600} fontSize={20}>
            Our Services
          </Typography>
          <Typography fontSize={16} variant="body2">
            The information provided when using the Services is not intended for distribution to or
            use by any person or entity in any jurisdiction or country where such distribution or
            use would be contrary to law or regulation or which would subject us to any registration
            requirement within such jurisdiction or country. Accordingly, those persons who choose
            to access the Services from other locations do so on their own initiative and are solely
            responsible for compliance with local laws, if and to the extent local laws are
            applicable.
          </Typography>
        </Stack>

        <Stack gap={2}>
          <Typography fontWeight={600} fontSize={20}>
            Changes to the Site or Services
          </Typography>
          <Typography variant="body2">
            We may update and change our Site, App or the Services from time to time to reflect
            changes to our products, our users needs and our business priorities or for any other
            reason at our discretion. We will try to give you reasonable notice of any major
            changes.
          </Typography>
        </Stack>

        <Stack gap={2}>
          <Typography fontWeight={600} fontSize={20}>
            Privacy
          </Typography>
          <Typography variant="body2">
            We care about data privacy and security. By using the Services, you agree to be bound by
            our Privacy Policy posted on the Services, which is incorporated into these Legal Terms.
            Please be advised that the Services are hosted in Poland. If you access the Services
            from any other region of the world with laws or other requirements governing personal
            data collection, use, or disclosure that differ from applicable laws in Poland, then
            through your continued use of the Services, you are transferring your data to our
            servers in Poland, and you consent to having your data processed in compliance with
            Polish law.
            <br />
            <br />
            If you are accessing the Services from the European Union, please note that we comply
            with the General Data Protection Regulation (GDPR), and your rights regarding data
            access, correction, deletion, and other legal protections are fully respected.
          </Typography>
        </Stack>

        <Stack gap={2}>
          <Typography fontWeight={600} fontSize={20}>
            Our Intellectual Property
          </Typography>
          <Typography variant="body2">
            We are the owner or the licensee of all intellectual property rights in our Services,
            including all source code, databases, functionality, software, website designs, audio,
            video, text, photographs, and graphics in the Services (collectively, the
            &quot;Content&quot;), as well as the trademarks, service marks, and logos contained
            therein (the &quot;Marks&quot;)
            <br />
            <br />
            Our Content and Marks are protected by copyright and trademark laws (and various other
            intellectual property rights and unfair competition laws) and treaties in the United
            States and around the world.
            <br />
            <br />
            The Content and Marks are provided in or through the Services &quot;AS IS&quot; fro your
            personal, non-commercial use or internal business purpose
          </Typography>
        </Stack>

        <Stack gap={2}>
          <Typography fontWeight={600} fontSize={20}>
            Support
          </Typography>
          <Typography variant="body2">
            You may contact Embargo for support in connection with the App or the Services by
            emailing us at <Link href={`mailto:jarvis@mealjoyapp.com`}>jarvis@mealjoyapp.com</Link>
          </Typography>
        </Stack>

        <Stack gap={2}>
          <Typography fontWeight={600} fontSize={20}>
            Limitations of Liability
          </Typography>
          <Typography variant="body2">
            IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD
            PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR
            PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES
            ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY
            OF SUCH DAMAGES.
            <br />
            <br />
            This limitation of liability will apply to the fullest extent permitted by law. Some
            jurisdictions do not allow the exclusion or limitation of certain damages, so some or
            all of the above exclusions and limitations may not apply to you.
          </Typography>
        </Stack>

        <Stack gap={2}>
          <Typography fontWeight={600} fontSize={20}>
            User Data
          </Typography>
          <Typography variant="body2">
            We will maintain certain data that you transmit to the Services for the purpose of
            managing the performance of the Services, as well as data relating to your use of the
            Services. Although we perform regular routine backups of data, you are solely
            responsible for all data that you transmit or that relates to any activity you have
            undertaken using the Services.
            <br />
            <br />
            We follow strict data protection policies and comply with regulations, such as the GDPR,
            to safeguard your personal data. You have the right to access, correct, or request
            deletion of your data as per our Privacy Policy.
            <br />
            <br />
            While we take measures to protect your data, we shall have no liability to you for any
            loss or corruption of any such data, and you hereby waive any right of action against us
            arising from any such loss or corruption.
          </Typography>
        </Stack>

        <Stack gap={2}>
          <Typography fontWeight={600} fontSize={20}>
            Data Retention
          </Typography>
          <Typography variant="body2">
            We retain user data for as long as necessary to fulfill the purposes for which it was
            collected, or as required by applicable laws. You can request deletion or access to your
            data at any time, and we will respond in compliance with applicable laws, such as GDPR
            or CCPA.
          </Typography>
        </Stack>
      </Stack>
    </Box>
  )
}
