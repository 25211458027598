import { useEffect } from 'react'
import { Analytics } from 'analytics'

export const useScrollPage = (sectionName, sectionRef) => {
  useEffect(() => {
    const section = sectionRef.current

    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0]

      if (entry.isIntersecting) {
        Analytics.scrollTo(sectionName)
        console.log(sectionName, entry.isIntersecting)
      }
    })

    observer.observe(section)
  }, [sectionName])
}
